<template>
  <div class="vip-container main-cnt">
    <div class="title">会员管理</div>
    <div class="content">
      <common-table ref="tableRef" tableHeight="calc(100vh - 320px)" :ischeck="false" :filters="filters"
        :ispaging="true" :apiName="VipApi.memberList" :columns="tableColumns" @edit="openDialog"
        @onDetailsBtn="onDetailsBtn" @onMemberProperty="onMemberProperty">
        <template #m_owner="{ row }">
          <div class="authentication" :class="row.m_owner == '1' ? 'btn-green' : 'btn-red'">{{ row.m_owner == '1' ? '是'
            : '否' }}</div>
        </template>
        <!-- <template #operate>
          <el-button type="primary" round @click="openDialog">
            <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
            新增会员</el-button
          >
        </template> -->
      </common-table>
    </div>
    <w-dialog ref="dialogRef" class="vip-dialog" :title="ruleForm.m_id ? '编辑会员' : '新增会员'" width="50%" btnWidth="140px"
      top="20vh" :confirmText="ruleForm.m_id ? '确认编辑' : '确认新增'" @wConfirm="handleSure">
      <el-form class="add-form" ref="formRef" :model="ruleForm" :rules="rules" labelPosition="top">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="会员姓名" prop="m_realname">
              <el-input v-model="ruleForm.m_realname" clearable placeholder="请输入会员姓名"></el-input>
            </el-form-item></el-col>
          <el-col :span="8">
            <el-form-item label="会员性别" prop="m_sex">
              <el-select v-model="ruleForm.m_sex" placeholder="请选择会员性别" clearable>
                <el-option label="未知" :value="0"></el-option>
                <el-option label="男" :value="1"></el-option>
                <el-option label="女" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="身份证号" prop="m_idcard">
              <el-input v-model="ruleForm.m_idcard" placeholder="请输入身份证号" clearable></el-input> </el-form-item></el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="联系方式" prop="m_mobile">
              <el-input v-model="ruleForm.m_mobile" placeholder="请输入联系方式" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="会员来源" prop="m_platform">
              <el-select v-model="ruleForm.m_platform" placeholder="请选择会员来源" clearable :disabled="true">
                <el-option v-for="el in PlatformOptions" :key="el.id" :label="el.name" :value="el.id"></el-option>
              </el-select> </el-form-item></el-col>
          <el-col :span="8">
            <el-form-item label="所属公司" prop="m_company">
              <el-input v-model="ruleForm.m_company" clearable placeholder="请输入所属公司"></el-input>
            </el-form-item></el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="职位名称" prop="m_position">
              <el-input v-model="ruleForm.m_position" placeholder="请输入职位名称" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="加入社群" prop="communityList">
              <el-select v-model="ruleForm.communityList" clearable multiple collapse-tags placeholder="请选择加入社群">
                <el-option :label="item.mc_name" :value="item.mc_id" v-for="item in communityOptions"
                  :key="item.mc_id"></el-option>
              </el-select> </el-form-item></el-col>
        </el-row>
      </el-form>
    </w-dialog>

    <MemberDetails ref="refMemberDetails"></MemberDetails>

    <!-- 会员房产 -->
    <w-dialog ref="dialogRoomRef" title="会员房产" width="55%" btnWidth="140px" top="10vh" :hideFooter="true">
      <w-title :title_name="'会员信息'"></w-title>
      <el-row :gutter="20" class="member-info">
        <el-col :span="8">
          <div class="flex">
            <div class="member-info-title">会员姓名</div>
            <div class="member-info-content">{{ infoDatas.m_realname }}</div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="flex">
            <div class="member-info-title">会员账号</div>
            <div class="member-info-content">{{ infoDatas.m_mobile }}</div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="flex">
            <div class="member-info-title">会员积分</div>
            <div class="member-info-content">{{ infoDatas.m_integration }}</div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="flex s-m-t">
            <div class="member-info-title">会员性别</div>
            <div class="member-info-content">
              {{ infoDatas.m_sex == '1' ? '男' : infoDatas.m_sex == '2' ? '女' : '未知' }}
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="flex s-m-t">
            <div class="member-info-title">会员生日</div>
            <div class="member-info-content">{{ infoDatas.m_birth }}</div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="flex s-m-t">
            <div class="member-info-title">会员等级</div>
            <div class="member-info-content">{{ infoDatas.m_level_label }}</div>
          </div>
        </el-col>
      </el-row>
      <div class="m-m-t">
        <div class="flex-btw align-center">
          <w-title :title_name="'会员房产'"></w-title>
          <div>
            <el-button type="primary" round @click="onManualBtn" class="manually-bind"
              v-if="authData.indexOf('m_8gGIMgPrxGJQOeCDQXkk05E5Ay') != -1">手动绑定</el-button>
          </div>
        </div>

        <common-table ref="tableRoomRef" tableHeight="350" :tableData="memberProperty" :columns="roomTableColumns"
          :ispaging="false" class="s-m-t" @onUnbindBtn="onUnbindBtn">
          <template #is_deliver="{ row }">
            <div class="flex align-center">
              <div class="dots" :class="row.is_deliver == '是' ? 'bg-green' : 'bg-orange'"></div>
              <div>{{ row.is_deliver }}</div>
            </div>
          </template>
        </common-table>
      </div>
    </w-dialog>

    <!-- 手动绑定房产 -->
    <ManuallyBind ref="manuallyRef" @submit="onConfirmBinding"></ManuallyBind>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed, watch, nextTick, } from "vue";
import { VipApi, BasicApi } from "@/plugins/api.js";
import { ElMessage, ElMessageBox, } from "element-plus";
import { checkStr } from "@/utils/common.js";
import MemberDetails from "../components/MemberDetails.vue";
import { useStore } from "vuex";
import ManuallyBind from "../components/ManuallyBind.vue";

// 权限
const store = useStore();
const menuTokens = computed(() => store.state.menuToken.menuTokens);
const authData = ref([]);
watch(
  () => menuTokens.value,
  (data) => {
    if (data.length) {
      authData.value = data;
    }
  },
  {
    deep: true,
    immediate: true,
  }
);

const communityOptions = ref([]);
const PlatformOptions = ref([]);
onMounted(() => {
  tableRef.value.tableLoad();

  BasicApi.getCommunity().then((res) => {
    communityOptions.value = res.Data;
    // filters.value[1].options = res.Data;
  });
  BasicApi.getPlatformOptions().then((res) => {
    PlatformOptions.value = res.Data;
    // filters.value[2].options = res.Data;
  });
});

const filters = ref([
  {
    filterType: "search",
    name: "keyword",
    value: "",
    placeholder: "请输入房号、会员账号、姓名或手机号",
  },
  // {
  //   filterType: "select",
  //   name: "mc_id",
  //   value: "",
  //   placeholder: "请选择所属社群",
  //   options: [],
  //   val: "mc_id",
  //   lab: "mc_name",
  // },
  // {
  //   filterType: "select",
  //   name: "m_platform",
  //   value: "",
  //   placeholder: "请选择会员来源",
  //   options: [],
  //   val: "id",
  //   lab: "name",
  // },
  {
    filterType: "select",
    name: "m_owner",
    value: "",
    placeholder: "请选择是否业主",
    options: [{ id: 1, name: '是' }, { id: 0, name: '否' }],
    val: "id",
    lab: "name",
  },
  {
    filterType: "select",
    name: "m_level",
    value: "",
    placeholder: "请选择会员等级",
    options: [{ id: 1, name: '注册会员' }, { id: 2, name: '铜牌会员' }, { id: 3, name: '银牌会员' }, { id: 4, name: '金牌会员' }, { id: 5, name: '钻石会员' }],
    val: "id",
    lab: "name",
  },
]);
/** 表格对象 */
const tableRef = ref(null);

/** 表格配置数据 */
const tableColumns = ref([
  {
    prop: "m_id",
    label: "会员ID",
  },
  {
    prop: "m_realname",
    label: "会员姓名",
  },
  {
    prop: "m_sex",
    label: "会员性别",
    type: "option",
    metaData: ["未知", "男", "女"],
    colorData: [
      "var(--text-color)",
      "var(--text-color)",
      "var(--text-color)",
    ],
  },
  {
    prop: "m_birth",
    label: "会员生日",
  },
  {
    prop: "m_mobile",
    label: "会员电话",
  },
  {
    prop: "m_owner",
    type: "customRender",
    label: "是否业主认证",
  },
  {
    prop: "m_integration",
    label: "积分合计",
  },
  // {
  //   prop: "m_platform",
  //   label: "会员来源",
  //   type: "option",
  //   metaData: ["", "微信", "系统后台"],
  //   colorData: ["", "var(--text-third-color)", "var(--text-third-color)"],
  // },
  {
    prop: "m_level_label",
    label: "会员等级",
  },
  {
    prop: "m_order_num",
    label: "订单合计",
  },
  {
    prop: "m_ctime",
    label: "注册时间",
    minWidth: 100,
  },
  {
    type: "operation",
    prop: "",
    label: "操作",
    minWidth: 280,
    bottons: [
      {
        name: "编辑",
        action: "edit",
        className: "theme-font-btn",
        token: "m_mx9wiI3QYNfu0g7T8h2tUJCZaAKO",
      },
      {
        name: "详情",
        action: "onDetailsBtn",
        className: "theme-font-btn",
        token: "m_46a71284bc22ebf783388d10cdf",
      },
      {
        name: "会员房产",
        action: "onMemberProperty",
        token: "m_US4FS28DHOwTZtmXRhH3CImNhC",
        className: "theme-font-btn",
      },
    ],
  },
]);

const dialogRef = ref(null);
const formRef = ref(null);
const ruleForm = ref({
  m_realname: "",
  m_sex: "",
  m_idcard: "",
  m_mobile: "",
  m_platform: "",
  m_company: "",
  m_position: "",
  communityList: [],
  community: "",
});
const rules = reactive({
  m_realname: [
    {
      required: true,
      message: "请输入会员姓名",
      trigger: "blur",
    },
  ],
  m_mobile: [
    {
      required: true,
      message: "请输入联系方式",
      trigger: "blur",
    },
  ],
  m_idcard: [
    {
      required: true,
      message: "请输入身份证号",
      trigger: "blur",
    },
  ],
  // m_company: [
  //   {
  //     required: true,
  //     message: "请输入所属公司",
  //     trigger: "blur",
  //   },
  // ],
  // m_position: [
  //   {
  //     required: true,
  //     message: "请输入公司职位",
  //     trigger: "blur",
  //   },
  // ],
});
const openDialog = (row) => {
  if (row.m_id) {
    //编辑
    const data = JSON.parse(JSON.stringify(row));
    data.communityList = [];
    if (data.community.length) {
      data.community.forEach((el) => {
        data.communityList.push(el.mc_id);
      });
    }
    ruleForm.value = data;
  } else {
    // 新增
    ruleForm.value = {
      m_realname: "",
      m_sex: "",
      m_idcard: "",
      m_mobile: "",
      m_platform: "",
      m_company: "",
      m_position: "",
      communityList: [],
      community: "",
    };
  }
  dialogRef.value.show();
};
/** 新增/编辑 确认提交 */
const handleSure = () => {
  formRef.value.validate((valid) => {
    if (valid) {
      dialogRef.value.isLoading = true;
      const data = JSON.parse(JSON.stringify(ruleForm.value));
      if (!checkStr(data.m_realname, "chinese")) {
        ElMessage.warning("请输入正确的会员姓名！");
        dialogRef.value.isLoading = false;
        return false;
      }
      if (!checkStr(data.m_mobile, "phone")) {
        ElMessage.warning("联系方式格式错误！");
        dialogRef.value.isLoading = false;
        return false;
      }
      if (!checkStr(data.m_idcard, "card")) {
        ElMessage.warning("身份证格式错误！");
        dialogRef.value.isLoading = false;
        return false;
      }
      if (data.communityList.length) {
        data.community = data.communityList.join(",");
      } else {
        data.community = "";
      }

      let parmas = {
        m_realname: data.m_realname,
        m_sex: data.m_sex,
        m_mobile: data.m_mobile,
        m_idcard: data.m_idcard,
        m_position: data.m_position,
        m_company: data.m_company,
        community: data.community,
        m_platform: data.m_platform,
      };
      let url = "";
      if (data.m_id) {
        // 编辑
        url = "memberUpdate";
        parmas["m_id"] = data.m_id;
      } else {
        // 新增
        url = "memberAdd";
      }
      VipApi[url](parmas).then((res) => {
        dialogRef.value.isLoading = false;
        const text = data.m_id ? "修改" : "新增";
        if (res.Code === 200) {
          ElMessage.success(`会员${text}成功！`);
          dialogRef.value.close();
          tableRef.value.tableLoad();
        } else {
          let msg = res.Message ? res.Message : `会员${text}失败！`;
          ElMessage.error(msg);
        }
      });
    }
  });
};
const refMemberDetails = ref(null);  // 详情对话框对象
/**
 * 
 * 详情按钮
 * 
 * **/
const onDetailsBtn = (row) => {
  refMemberDetails.value.openDialog(row, VipApi);
}
const dialogRoomRef = ref(null);  // 会员房产
const infoDatas = ref('');  // 详情数据
const tableRoomRef = ref(null);  // 会员房产表格对象
const memberProperty = ref([]);  // 会员房产表格数据
const manuallyRef = ref(null);  // 手动绑定对象
/** 表格配置数据 */
const roomTableColumns = ref([
  {
    prop: "project_name",
    label: "项目",
    minWidth: 280,
  },
  {
    prop: "roomnum",
    label: "房号",
    minWidth: 100,
  },
  {
    prop: "buildareas",
    label: "面积",
  },
  {
    prop: "is_deliver",
    label: "是否交房",
    type: 'customRender',
  },
  {
    prop: "mr_create_time",
    label: "认证时间",
    minWidth: 140,
  },
  {
    prop: "type_text",
    label: "认证方式",
  },
  {
    type: "operation",
    prop: "",
    label: "操作",
    minWidth: 100,
    bottons: [
      {
        name: "解绑",
        action: "onUnbindBtn",
        token: "m_DHVL1YmrAKQBsLKb5xdWvEZdF1",
        className: "error-font-btn",
      },
    ],
  },
]);
/**
 * 
 * 点击业主是否认证
 * 
 * **/
const onMemberProperty = (row) => {
  VipApi.getMemberRoom({ m_id: row.m_id }).then((res) => {
    if (res.Code === 200) {
      infoDatas.value = res.Data.member;
      dialogRoomRef.value.show();
      getBindRooms(row);
    } else {
      ElMessage.error(res.Message);
    }
  });
}
/**
 * 
 * 获取会员房产表格数据
 * 
 * **/
const getBindRooms = (row) => {
  VipApi.getBindRooms({ m_id: row.m_id }).then((res) => {
    if (res.Code === 200) {
      memberProperty.value = res.Data ? res.Data : [];
      nextTick(() => {
        tableRoomRef.value.tableLoad();
      })
    } else {
      ElMessage.error(res.Message);
    }
  });
}
/**
 * 
 * 点击手动绑定按钮
 * 
 * **/
const onManualBtn = () => {
  manuallyRef.value.openDialog(infoDatas.value, 'member');
}
/**
 * 
 * 手动绑定确定按钮
 * 
 * **/
const onConfirmBinding = (data) => {
  VipApi.addBindRoom(data).then((res) => {
    manuallyRef.value.closeDialogLoading();
    if (res.Code === 200) {
      manuallyRef.value.closeDialog();
      ElMessage.success('绑定成功！');
      getBindRooms(data);
      tableRef.value.tableLoad();
    } else {
      ElMessage.error(res.Message);
    }
  });
}
/**
 * 
 * 解绑按钮
 * 
 * **/
const onUnbindBtn = (row) => {
  ElMessageBox.confirm("是否确认解绑该房产？", "解绑确认", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
  })
    .then(() => {
      VipApi.unBindRoom({ mr_id: row.mr_id }).then((res) => {
        if (res.Code === 200) {
          ElMessage.success("解绑成功！");
          getBindRooms({ m_id: row.mr_m_id });
          tableRef.value.tableLoad();
        } else {
          ElMessage.error(res.Message);
        }
      });
    })
    .catch(() => { });
}
</script>
<style lang="scss">
.vip-container {
  font-family: "Source Han Sans CN";

  .content {
    padding: 15px 20px 20px;
  }

  .vip-dialog {
    .el-dialog {
      min-width: 600px;

      .el-dialog__body {
        padding: 30px;

        .el-divider--horizontal {
          margin: 10px 0;
        }
      }
    }
  }

  .authentication {
    width: 54px;
    height: 24px;
    border-radius: 4px;
    text-align: center;
  }

  .member-info-title {
    color: #92979E;
    text-align: left;
    width: 80px;
  }

  .member-info-content {
    color: #262C30;
  }

  .room-icon {
    margin-left: 5px;
    margin-top: 1px;
    cursor: pointer;
  }

  .manually-bind {
    width: 100px;
    min-height: 36px;
    padding: 0;
  }
}
</style>
